import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import { createStore, combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';

const fbConfig = {
    apiKey: 'AIzaSyA6Cju7N4ebfGDAHlzaA7vybXIbXzFBhOA',
    authDomain: 'navalexnet.firebaseapp.com',
    databaseURL: 'https://navalexnet.firebaseio.com',
    projectId: 'navalexnet',
    storageBucket: 'navalexnet.appspot.com',
    messagingSenderId: '855269997993',
    appId: '1:855269997993:web:1589f8fd11503dbc15d8b8',
    measurementId: 'G-HBLK5EE36L',
};

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
});

// Create store with reducers and initial state
const initialState = {};

export const store = createStore(
    rootReducer,
    initialState,
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
};
