import React, { useEffect, useState } from 'react';
import './Project.scss';
import CancelIcon from '@material-ui/icons/Cancel';

import TweenOne from 'rc-tween-one';
import { OverPack } from 'rc-scroll-anim';
import {Button, Card, CardContent, Chip, Container, Grid, Typography} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import stringToColour from '../../Utils/color';

function ProjectHex({onClick, project}) {
    return (
        <li className="hex canHover" style={{ opacity: 0, top: 200, scale: 0.5 }}>
            <div className="hexIn">
                <div className="hexLink" onClick={onClick}>
                    <img src={project.image} alt={project.title}/>
                    <h1>{project.title}</h1>
                    <p>{project.description}</p>
                </div>
            </div>
        </li>
    );
}

function ProjectModal({project, currentState, onClose}) {
    const modalDefaultAnimation = [
            {
                type: 'from',
                opacity: 0,
            },
            {
                type: 'from',
                scale: 0,
                y: -100
            }
        ],
        [modalAnimation, setModalAnimation] = useState(modalDefaultAnimation),
        modalClose = () => {
            setModalAnimation([
                {
                    type: 'to',
                    opacity: 0
                },
                {
                    type: 'to',
                    scale: 0,
                    y: -100,
                    onComplete: () => {
                        onClose();
                        setModalAnimation(modalDefaultAnimation);
                    }
                }
            ]);
        };

    return (
        currentState && project ?
            <TweenOne animation={modalAnimation[0]} className={'project-modal-wrapper'}
                      onClick={modalClose}>
                <TweenOne animation={modalAnimation[1]} className={'project-modal'}
                          onClick={e => e.stopPropagation()}>
                    <CancelIcon className="close-icon" onClick={modalClose}/>
                    <div className="modal-header">
                        <img src={project.image} alt={project.title}/>
                        {project.link &&
                            <div className="modal-link">
                                <Button component="a" href={project.link} target="_blank"
                                        variant="contained"
                                        color="primary">Voir le projet</Button>
                            </div>
                        }
                    </div>
                    <Container>
                        <div className="modal-content">
                            <h2 className="modal-title">{project.title}</h2>
                            <Grid container spacing={2}>
                                <Grid item sm={8}>{project.longDescription}</Grid>
                                <Grid item sm={4}>
                                    {project.tags && project.tags.map((tag, key) => (
                                        <Chip key={key} label={tag}
                                              style={{
                                                  backgroundColor: stringToColour(tag),
                                                  margin: '5px'
                                              }}/>
                                    ))}
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </TweenOne>
            </TweenOne>
            : <></>
    );
}

export default function Projects() {
    const hexAnimation = {
        opacity: 1,
        top: 0,
        scale: 1
    };
    const firebase = useFirebase(),
        [projects, setProjects] = useState([]),
        [modalState, setModalState] = useState(false),
        [modalInfos, setModalInfos] = useState({}),
        openModal = (infos) => {
            setModalInfos(infos);
            setModalState(true);
        },
        closeModal = () => {
            setModalInfos({});
            setModalState(false);
        };

    useEffect(() => {
        firebase.ref('projects')
            .orderByChild('order')
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    let projectsArray = [],
                        count = 0;

                    snapshot.forEach(item => {
                        const itemVal = item.val();
                        let imagePath = '';

                        Object.entries(itemVal.image)
                            .forEach(img => {
                                imagePath = img[1].fullPath;
                            });

                        firebase.storage()
                            .ref(imagePath)
                            .getDownloadURL()
                            .then(url => {
                                projectsArray[itemVal.order] = {
                                    title: itemVal.title,
                                    description: itemVal.shortDescription,
                                    longDescription: itemVal.longDescription,
                                    tags: itemVal.tags,
                                    link: itemVal.link,
                                    image: url
                                };
                                count++;
                                if (count >= snapshot.numChildren()) setProjects(projectsArray);
                            });
                    });
                }
            });
    }, [firebase]);
    return (
        <div className="nava-section projects">
            <OverPack playScale={0.4} component="div" className="nava-title">
                <TweenOne component={Card} key="testitest" raised style={{marginBottom: '80px'}} animation={{type: 'from', y: -100, opacity: 0}}>
                    <CardContent className="card-titled">
                        <div className="skill-title">
                            <Typography variant="h2">Projets</Typography>
                        </div>
                    </CardContent>
                </TweenOne>
            </OverPack>

            <OverPack playScale={0.2} component="ul" className="hexGrid" style={{ minHeight: '100%' }}>
                {
                    projects.map((item, id) => (
                        <TweenOne
                            component={ProjectHex}
                            key={`project_${id}`}
                            animation={{...hexAnimation, delay: (id*100)}}
                            project={item}
                            onClick={() => {
                                openModal(item);
                            }}
                        />
                    ))
                }
            </OverPack>
            <ProjectModal currentState={modalState} project={modalInfos} onClose={closeModal}/>
        </div>
    );
}
