import React from 'react';
import './Visage.scss';
import RcTweenOne from 'rc-tween-one';
import SvgDrawPlugin from 'rc-tween-one/lib/plugin/SvgDrawPlugin';
import ScrollAnim from 'rc-scroll-anim';

export default function Visage() {
    const svgLogoAnimation = {
        SVGDraw: 0,
        duration: 1000,
        type: 'from',
        opacity: 0,
    };
    const svgLogoEllipseAnimation = {
        type: 'from',
        opacity: 0,
        duration: 1000,
        delay: 1000,
    };
    RcTweenOne.plugins.push(SvgDrawPlugin);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="visage" viewBox="0 0 107.09 155.84">
            <ScrollAnim.OverPack
                playScale={0.2}
                component="g"
                stroke="#e65460"
                transform="translate(-37.512508 -48.880541)"
            >
                <RcTweenOne
                    key="1"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M48.643
        128.02c-1.0182-15.933-2.7178-52.593 10.691-57.997 18.534-4.4344 23.32 7.0348 35.012 10.156 9.8691-2.9935
        17.624-17.476 30.736-12.829 11.056 11.314 9.1424 27.44 10.958 41.16v18.709c.18114 11.76.25601 23.52-.26727
        35.28-2.301 10.877-7.8804 20.66-15.234 29.4-3.7544 3.5866-7.9182 7.0502-14.165 9.889-6.8042 2.7424-15.112
        3.2734-21.916
        1.0691-5.6497-2.3764-11.24-5.4967-16.571-10.156-10.087-9.499-15.269-21.53-17.105-32.874-1.8253-9.9342-3.7782-19.792-2.1382-31.805z"
                />
                <RcTweenOne
                    key="2"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    strokeLinecap="round"
                    d="M47.956 124.4c-2.0093-.99655-3.5408-2.3117-5.1972-3.5435-3.3931-.99187-3.5926.90581-4.4885 2.1734-1.0528
        5.5163 1.3305 9.7957 3.3073 14.221 1.3063 4.7653.64279 6.1974 1.2284 8.6462 2.3435 3.574 4.3638 3.7545 6.4728
        4.8664"
                />
                <RcTweenOne
                    key="3"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M136.04 124.57c.59815-4.3957
        3.1295-4.2924 5.479-4.2763 2.1346.97999 2.0795 1.96 2.5391 2.94.0469 1.868.23334 3.6474-.73499 5.479-1.0383
        1.9941-1.7412 3.8441-2.3386 6.4813-.84805 3.7092-.51096 7.327-2.0713 11.827-.33369.9738-1.0946 1.7645-2.6727
        2.205m-.20045-27.088c2.6013-5.5034 4.946-11.123 5.6127-17.506.5707-8.9865
        1.2194-17.928-4.8108-30.736-5.9748-10.49-15.408-18.36-31.738-23.119-4.1534-1.3601-23.357-4.5849-43.72
        8.0153-7.6495 5.3724-15.262 10.789-17.686 28.868-.28312 7.9834-1.8419 15.704-.13363 23.854 1.071 4.8695 2.0319
        9.4363 4.3908 12.883"
                />
                <RcTweenOne
                    key="4"
                    animation={svgLogoAnimation}
                    component="ellipse"
                    fill="none"
                    cx="79.368"
                    cy="117.02"
                    strokeLinecap="round"
                    rx="10.284"
                    ry="3.8908"
                    transform="rotate(3.254)"
                />
                <RcTweenOne
                    key="5"
                    animation={svgLogoAnimation}
                    fill="none"
                    component="ellipse"
                    cx="109.13"
                    cy="126.61"
                    strokeLinecap="round"
                    rx="9.9455"
                    ry="3.7425"
                    transform="rotate(-2.698)"
                />
                <RcTweenOne
                    key="6"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M57.588 111.32c2.779-.18438
        3.577-1.9655 6.5229-2.3332 5.7885-.0756 5.8054 1.8002 16.467 1.2136 2.3489-.24805 3.3208-1.0612
        2.5759-2.0955-3.9323-3.5922-12.383-1.8459-19.633-2.2669-3.9752.17044-6.7828 3.1021-8.2429
        5.1017-.72782.95921.90448.42607 2.3095.38027zm73.664
        2.8325c-1.096-1.4952-2.4519-2.8171-4.4885-3.6853-4.7695-1.1497-11.801 1.3228-16.783 1.0003-1.3998-.3128-5.8168
        1.6764-6.1919-1.8292 2.499-3.3276 15.598-3.0056 21.112-2.5318 2.2576.40686 5.0361 1.2139 6.3979 3.2662.33552
        1.2097.49187 2.4451-.0472 3.7798z"
                />
                <RcTweenOne
                    key="7"
                    animation={svgLogoEllipseAnimation}
                    component="ellipse"
                    className="fill"
                    cx="72.241"
                    cy="121.14"
                    strokeLinecap="round"
                    strokeWidth=".4"
                    rx="4.394"
                    ry="2.6695"
                />
                <RcTweenOne
                    key="8"
                    animation={svgLogoEllipseAnimation}
                    component="ellipse"
                    className="fill"
                    cx="115.11"
                    cy="121.15"
                    strokeLinecap="round"
                    strokeWidth=".4"
                    rx="4.394"
                    ry="2.6695"
                />
                <RcTweenOne
                    key="9"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M90.003 120.87c-.08496 20.372-3.1032 22.627-6.1472 24.722-2.4212 1.5176-2.6937 3.9265.53454 6.0804 1.4523.92476
    6.4547-.41643 8.7531.8018 1.3494.67629 3.6003.98738 5.2118.20046 2.4158-1.2676 5.0014-1.2904 7.684-.60136
    3.6404.56444 3.8779-4.4098 2.5391-5.5458-4.8023-3.5203-8.7854-14.085-9.2876-26.326"
                />
                <RcTweenOne
                    key="10"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M48.413 143.69c8.5186 14.365 16.249 30.006 30.899 36.385 11.932 5.9125 19.586 5.1847 27.93.735 10.609-5.4422
        18.501-14.964 28.799-36.694"
                />
                <RcTweenOne
                    key="11"
                    animation={svgLogoEllipseAnimation}
                    component="path"
                    className="fill"
                    strokeLinejoin="round"
                    strokeWidth=".71428"
                    d="M56.634 110.26c.7427-.89679 2.5595-2.3848 3.4877-2.8565 1.909-.9702 2.5518-1.0276 10.551-.94295 8.217.087
        9.0089.18338 10.927 1.3298 1.9048 1.1382 1.2837 1.6288-2.2915
        1.8099-3.3955.17199-6.6859-.04-9.8238-.63302-2.237-.42274-3.1171-.50332-4.8192-.44131-2.0781.0757-2.0865.0779-4.0327
        1.0668-1.5564.79078-2.2039 1.0146-3.2057 1.1082l-1.2553.11722zm73.178
        1.4614c-.54759-.49896-1.4831-1.1542-2.0789-1.4561-1.0666-.54046-1.1311-.54884-4.2016-.54549-2.4582.003-3.8584.11644-6.6146.53743-1.923.29372-4.522.5601-5.7757.59195-1.2537.0319-2.8468.11868-3.5402.19293-1.6569.17744-2.5147.005-2.9002-.58343-.28445-.43413-.28336-.49456.0149-.82414
        1.3358-1.476 8.1181-2.448 15.319-2.1954 4.0091.14062 5.4811.32153 7.1661.88065 1.4447.47938 3.0412 1.516 3.3851
        2.1978.31092.61658.57194 2.1318.36511 2.1195-.0792-.005-.592-.41682-1.1396-.91577z"
                />
                <RcTweenOne
                    key="12"
                    animation={svgLogoEllipseAnimation}
                    component="path"
                    className="fill"
                    strokeLinejoin="round"
                    strokeWidth=".71428"
                    d="M47.04
        121.39c-.79057-1.7529-1.4351-3.8058-2.211-7.0422-1.0076-4.2028-1.2589-6.0225-1.37-9.9219-.09831-3.4506-.05046-4.3938.80534-15.875.23422-3.1421
        1.2432-7.7705 2.4496-11.236 2.4142-6.9357 5.9947-11.484 13.084-16.62 12.767-9.2495 32.009-13.104 45.685-9.1509
        7.4032 2.1399 15.119 5.9898 20.222 10.09 4.8955 3.9337 9.4018 9.6982 11.587 14.822 3.2688 7.6649 4.4447 14.633
        4.0054 23.732-.22738 4.7094-.29731 5.3623-.86895 8.1122-.55035 2.6474-1.4724 5.5283-2.7789 8.6825l-.98304
        2.3733-.11172-6.6146c-.0615-3.638-.21023-7.5501-.33063-8.6934s-.39036-4.3751-.59991-7.1816c-.46523-6.2309-.79728-8.9865-1.5205-12.619-.95906-4.8163-2.6978-9.3175-4.9081-12.706-1.3429-2.0586-3.5163-4.6135-4.12-4.843-2.0896-.79446-6.1226-1.0203-8.416-.47126-3.4424.82412-6.3644
        2.4809-11.953 6.7774-5.2457 4.0329-7.554 5.524-9.752
        6.2994-.66935.23616-.8385.21731-2.0923-.23323-2.1227-.76276-4.4629-2.0032-8.2622-4.3796-5.886-3.6816-8.7992-5.0245-12.568-5.7936-4.314-.88036-11.23-.33194-13.978
        1.1084-4.8094 2.5209-8.0531 9.6683-9.6356 21.232-.86578 6.3263-1.2472 17.631-.89103 26.411.1095 2.6995.17424
        4.933.14388 4.9634-.03036.0304-.31459-.51994-.63162-1.2229z"
                />
                <RcTweenOne
                    key="13"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M76.272 178.23c-.19145-21.956 37.89-22.5 37.725-1.8819m-42.77-1.4673c-.72252-23.55 43.736-23.876
        47.536-3.2244"
                />
                <RcTweenOne
                    key="14"
                    animation={svgLogoEllipseAnimation}
                    component="path"
                    className="fill"
                    strokeLinejoin="round"
                    strokeWidth=".71428"
                    d="M89.418
        203.34c-3.3606-.4583-5.9161-1.3693-10.167-3.6244-4.5548-2.4162-8.6258-5.3048-11.79-8.3654-7.2897-7.0511-12.576-16.494-15.133-27.031-.40905-1.6858-2.146-10.981-2.4366-13.04-.05136-.3638-.22595-1.5544-.38798-2.6458-.16202-1.0914-.29591-2.0694-.29752-2.1734-.0016-.10394.64595.91659
        1.439 2.2679 7.084 12.07 10.71 17.405 14.951 22 2.3214 2.5147 5.0711 4.8995 7.6638 6.6467 4.5279 3.0513 11.198
        5.937 16.196 7.0067 1.6166.34599 2.5798.42461 5.1972.4242 2.8648-.00026 3.4369-.0563 5.2814-.51555 6.0845-1.5149
        12.523-5.2562 17.505-10.172 5.4243-5.3516 10.136-12.322 15.886-23.5l2.2359-4.3467.003 2.2679c.002 1.2473-.0855
        4.9039-.19392 8.1258-.17688 5.2546-.2465 6.0526-.6761 7.7485-2.119 8.3651-6.3777 16.628-12.794 24.824-4.0317
        5.1499-11.651 10.634-17.511 12.604-4.8113 1.6171-10.171 2.1536-14.973 1.4988z"
                />
                <RcTweenOne
                    key="15"
                    animation={svgLogoEllipseAnimation}
                    component="path"
                    className="fill"
                    strokeLinejoin="round"
                    strokeWidth=".99999"
                    d="M73.715 176.15l-1.8521-1.2778.07396-1.4185c.20597-3.9506 1.4595-6.687 4.4101-9.6267 4.5477-4.5311
        11.319-6.7293 19.705-6.397 6.8833.27269 11.9 1.9459 16.627 5.5453 2.3648 1.8007 4.7623 5.432 5.2168
        7.9014.15298.83114.0452 1.0117-1.5354 2.5725l-1.6999
        1.6787-.18381-1.2257c-.89254-5.9519-5.1021-10.294-11.984-12.362-2.2607-.67921-8.1678-.95672-10.819-.50828-8.9123
        1.5073-14.904 7.1143-15.911 14.89l-.19505 1.5057z"
                />
                <RcTweenOne
                    key="16"
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    strokeWidth=".6"
                    d="M81.685
        170.43c8.0735 2.4656 18.598 2.46 27.419-.30808"
                />
            </ScrollAnim.OverPack>
        </svg>
    );
}
