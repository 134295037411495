import React from 'react';
import {
    Box,
    Paper,
    Typography,
} from '@material-ui/core';
import AdminProjectAdd from './ProjectAdd';
import AdminProjectList from './ProjectList';


function AdminProject() {
    return (
        <>
            <Box mb={4}>
                <Paper className="dash-title">
                    <Typography variant="h4">Administration: Projet</Typography>
                </Paper>
            </Box>

            <AdminProjectAdd/>

            <AdminProjectList/>
        </>
    );
}

export default AdminProject
