import React, {useState} from 'react';
import './Admin.scss';
import {
    BottomNavigation, BottomNavigationAction,
    Box,
    Container
} from '@material-ui/core';
import AdminProject from "./Project/Project";
import {BrowserRouter as Router, Route, Switch, Link, useLocation} from "react-router-dom";
import AdminConfig from "./Config/Config";
import {Assignment, Build} from "@material-ui/icons";
import TweenOne from "rc-tween-one";

const routerPrefix = '/admin'
const routerConfig = [
    {label: 'Configs', icon: <Build />, path: '/', component: <AdminConfig />},
    {label: 'Projets', icon: <Assignment />, path: '/projects', component: <AdminProject />}
]

function Admin() {
    const currentLocation = useLocation()
    const [navigation, setNavigation] = useState(routerConfig.findIndex(item => currentLocation.pathname === routerPrefix + item.path))
    const routerArray = [...routerConfig].reverse()

    return (
        <Router basename={routerPrefix}>
            <Box component={Container} pb={10} className="dashboard">
                <Switch>
                    {routerArray.map((item, id) => (
                        <Route path={item.path} key={id}>
                            <TweenOne animation={{
                                type: 'from',
                                opacity: 0,
                                y: 100
                            }}>
                                {item.component}
                            </TweenOne>
                        </Route>
                    ))}
                </Switch>
            </Box>

            <BottomNavigation
                style={{position: 'fixed', bottom: 0, left: 0, right: 0}}
                value={navigation}
                onChange={(event, value) => {
                    setNavigation(value)
                }}
            >
                {routerConfig.map((item, id) => (
                    <BottomNavigationAction component={Link} to={item.path} key={id} label={item.label} icon={item.icon} />
                ))}
            </BottomNavigation>
        </Router>
    );
}

export default Admin
