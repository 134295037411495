import React, {useEffect, useState} from 'react';
import './Introduction.scss';
import {Grid, LinearProgress} from '@material-ui/core';
import RcTweenOne from 'rc-tween-one';
import ScrollAnim from 'rc-scroll-anim';
import TextyAnim from 'rc-texty';
import Visage from './Visage/Visage';
import {useFirebase} from "react-redux-firebase";

export default function Introduction() {
    const firebase = useFirebase()
    const [introText, setIntroText] = useState(undefined)
    const textLineAnimation = {
        type: 'from',
        SVGDraw: 0,
        opacity: 0,
    };

    useEffect(() => {
        firebase.ref('configs/intro').on('value', snapshot => {
            if (snapshot.exists()) setIntroText(snapshot.val())
        })
    }, [introText, firebase])
    return (
        <div className="nava-section introduction" id="section2">

            <Grid container className="intro-wrapper">
                <ScrollAnim.OverPack component={Grid} playScale={0.5} item sm={9} md={6} className="intro-column">
                    <RcTweenOne
                        key="introTextWrapper"
                        animation={{
                            type: 'from', x: -40, y: -40, opacity: 0, scale: 1.4,
                        }}
                        className="intro-text"
                    >
                        <TextyAnim key="introTitle" interval={2} component="h2" type="mask-top">
                            Qui Suis-je ?
                        </TextyAnim>
                        {!introText ? <LinearProgress /> : <TextyAnim key="introText" interval={2} component="p" type="mask-top">{introText}</TextyAnim>}
                        <ScrollAnim.OverPack
                            component="svg"
                            playScale={0.25}
                            className="intro-line"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 907.91 175.27"
                        >
                            <RcTweenOne
                                key="textLine"
                                animation={textLineAnimation}
                                component="path"
                                fill="none"
                                stroke="#000"
                                strokeLinecap="round"
                                strokeWidth="5"
                                d="M2.5 2.5v117.87c-.0422 56.799-.25349 52.15 52.051 52.15h700.38c18.161-.18727 30.231-17.038 42.841-38.081 12.722-22.749 28.543-68.246 38.166-68.246 9.2167 0 46.316 71.053 69.474 106.58"
                            />
                        </ScrollAnim.OverPack>

                    </RcTweenOne>
                </ScrollAnim.OverPack>
                <Grid item sm={3} md={6} className="intro-column">
                    <Visage />
                </Grid>
            </Grid>
        </div>
    );
}
