import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, ButtonGroup,
    Card,
    CardContent, Divider,
    Grid, IconButton, LinearProgress, Paper,
    TextField,
    Typography
} from '@material-ui/core';
import {Add, Remove} from '@material-ui/icons';
import {withFirebase} from 'react-redux-firebase';
import {connect} from 'react-redux';
import {v4 as uuidv4} from 'uuid'
import RcTweenOne from "rc-tween-one";

function AdminConfigContact({uid, item, onChange, onAdd, onRemove}) {
    const [name, setName] = useState(item.name)
    const [value, setValue] = useState(item.value)

    const handleChange = e => {
        if (e.target.name === 'name') setName(e.target.value)
        if (e.target.name === 'value') setValue(e.target.value)
    }

    useEffect(() => {
        onChange(uid, {name, value, uid})
    }, [name, value, onChange, uid])

    return (
        <Box mb={2}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        onChange={handleChange}
                        value={name}
                        fullWidth
                        name="name"
                        label="Nom"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        onChange={handleChange}
                        value={value}
                        fullWidth
                        name="value"
                        label="Valeur"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={() => {
                        onRemove(uid)
                    }}><Remove/></IconButton>
                    {onAdd && <IconButton color="primary" onClick={onAdd}><Add/></IconButton>}
                </Grid>
            </Grid>
        </Box>
    )
}

class AdminConfig extends React.Component {
    constructor(props) {
        super(props)

        const {firebase} = this.props

        this.state = {
            loaded: false,
            intro: '',
            contactInfos: []
        }

        firebase.ref('configs').once('value').then(snapshot => {
            if (snapshot.exists()) {
                this.setState({
                    loaded: true,
                    ...snapshot.val()
                })
            } else {
                this.setState({loaded: true})
            }
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const {firebase} = this.props
        const {intro, contactInfos} = this.state

        firebase.ref('configs/intro').set(intro)
        firebase.ref('configs/contactInfos').set(contactInfos)
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleContactChange = (id, obj) => {
        let {contactInfos} = this.state

        contactInfos[contactInfos.findIndex(el => el.uid === id)] = obj
        this.setState({contactInfos})
    }

    handleRemoveContactInfo = id => {
        const {contactInfos} = this.state

        this.setState({contactInfos: contactInfos.filter(item => item.uid !== id)})
    }

    handleAddContactInfo = () => {
        let {contactInfos} = this.state

        contactInfos.push({name: '', value: '', uid: uuidv4()})
        this.setState({contactInfos})
    }

    render() {
        const {loaded, intro, contactInfos} = this.state

        return (
            !loaded ? <LinearProgress/> :
                <>
                    <Box mb={4}>
                        <Paper className="dash-title">
                            <Typography variant="h4">Administration: Configuration</Typography>
                        </Paper>
                    </Box>

                    <Card>
                        <CardContent>
                            <form onSubmit={this.handleSubmit}>
                                <Box mb={1}>
                                    <Typography variant="h5">Zone Présentation</Typography>
                                </Box>

                                <Divider/>

                                <Box mb={4} mt={2}>
                                    <TextField
                                        onChange={this.handleChange}
                                        value={intro}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        variant="outlined"
                                        name="intro"
                                        label="Texte d'introduction"
                                        helperText='Texte de la section "Qui suis-je ?"'
                                    />
                                </Box>

                                <Box mb={1}>
                                    <Typography variant="h5">Zone Contact</Typography>
                                </Box>

                                <Divider/>

                                <Box mb={4} mt={2}>
                                    {contactInfos.length === 0 &&
                                    <Button onClick={this.handleAddContactInfo} color="primary"
                                            variant="contained">Ajouter</Button>}
                                    {contactInfos.map((item, id) => (
                                        <RcTweenOne
                                            key={item.uid}
                                            animation={{
                                                type: 'from',
                                                x: -100,
                                                opacity: 0,
                                                delay: 100 * id
                                            }}>
                                            <AdminConfigContact
                                                uid={item.uid}
                                                item={item}
                                                onChange={this.handleContactChange}
                                                onRemove={this.handleRemoveContactInfo}
                                                onAdd={id === contactInfos.length - 1 ? this.handleAddContactInfo : undefined}
                                            />
                                        </RcTweenOne>
                                    ))}
                                </Box>

                                <Box mt={4}>
                                    <ButtonGroup>
                                        <Button type="submit" color="primary" variant="contained"
                                                disabled={!intro}>Modifier</Button>
                                    </ButtonGroup>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </>
        );
    }
}

const enhance = connect(
    ({firebase: {auth, profile}}) => ({
        auth,
        profile
    })
)

export default withFirebase(enhance(AdminConfig))
