import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import App from './App';
import { store, rrfProps } from './Utils/firebase-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#54a29e',
            main: '#2A8B86',
            dark: '#1d615d',
            contrastText: '#fff',
        },
        secondary: {
            light: '#aee197',
            main: '#9ADA7E',
            dark: '#6b9858',
            contrastText: '#000',
        },
    }
});

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <MuiThemeProvider theme={darkTheme}>
                <App/>
            </MuiThemeProvider>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root'),
);
