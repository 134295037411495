import React, { useEffect, useState } from 'react';
import {
    Box, Button, ButtonGroup, Chip,
    Paper,
    Table, TableBody,
    TableCell,
    Tooltip,
    TableContainer,
    TableHead,
    TableRow, LinearProgress
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { ArrowUp, ArrowDown, TrashCan } from 'mdi-material-ui';

import { useFirebase } from 'react-redux-firebase';

import stringToColour from '../../Utils/color';
import ProjectEdit from './ProjectEdit';

import RcTweenOne from "rc-tween-one";

export default function AdminProjectList() {
    const firebase = useFirebase(),
        [projectList, setProjectList] = useState([]),
        [projectEdit, setProjectEdit] = useState(undefined),
        handleMoveProject = (dir, project, key) => {
            let replaceProject = undefined;

            switch (dir) {
            case 'up':
                if (key > 0) replaceProject = projectList[key - 1];
                break;
            case 'down':
                if (key < projectList.length - 1) replaceProject = projectList[key + 1];
                break;
            default:
                break;
            }

            if (replaceProject) {
                const newOrder = replaceProject.order;

                replaceProject.snapshot.ref.update({ order: project.order });
                project.snapshot.ref.update({ order: newOrder });
            }
        },
        handleRemoveProject = project => {
            if (project.image) {
                Object.entries(project.image)
                    .forEach(image => {
                        firebase.deleteFile(image[1].fullPath)
                            .then();
                    });
            }
            project.snapshot.ref.remove();
        },
        handleEditProject = project => {
            if (project !== projectEdit) {
                if (projectEdit) {
                    setProjectEdit(undefined);
                    setTimeout(() => {
                        setProjectEdit(project);
                    }, 500);
                }
                else setProjectEdit(project);
            }
        }

    useEffect(() => {
        firebase.ref('projects')
            .orderByChild('order')
            .on('value', snapshot => {
                let list = [];

                if (snapshot.exists()) {
                    snapshot.forEach(item => {
                        list.push({
                            ...item.val(),
                            refKey: item.key,
                            snapshot: item
                        });
                    });
                }
                setProjectList(list);
            });
    }, [firebase]);

    return (
        <Box mt={2}>
            {projectEdit && <Box mb={2}>
                <ProjectEdit project={projectEdit} onComplete={() => { setProjectEdit(undefined) }}/>
            </Box>}
            <TableContainer component={Paper}>
                <Table className="dash-table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell className="long-cell">Titre</TableCell>
                            <TableCell align="right">Lien</TableCell>
                            <TableCell align="right">Tags</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!projectList.length && <TableRow><TableCell colSpan={5}><LinearProgress /></TableCell></TableRow>}
                        {projectList.map((item, key) => (
                            <RcTweenOne component={Tooltip} animation={{
                                type: 'from',
                                x: -100,
                                opacity: 0,
                                delay: 100 * key
                            }} key={item.refKey} title={item.snapshot.ref.path.toString()}
                                     placement="left">
                                <TableRow>
                                    <TableCell>
                                        <span>{item.order}</span>
                                    </TableCell>
                                    <TableCell className="long-cell" component="th"
                                               scope="row">{item.title}</TableCell>
                                    <TableCell align="right">{item.link}</TableCell>
                                    <TableCell align="right">{item.tags.map((tag, key) => (
                                        <Chip key={key} label={tag}
                                              style={{ backgroundColor: stringToColour(tag) }}/>
                                    ))}</TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup size="small">
                                            <Button variant="contained" onClick={() => {
                                                handleMoveProject('up', item, key);
                                            }} disabled={key === 0}>
                                                <ArrowUp/>
                                            </Button>
                                            <Button variant="contained" onClick={() => {
                                                handleMoveProject('down', item, key);
                                            }} disabled={key === projectList.length - 1}>
                                                <ArrowDown/>
                                            </Button>
                                            <Button variant="contained" color="primary"
                                                    onClick={() => {
                                                        handleEditProject(item);
                                                    }}>
                                                <Edit/>
                                            </Button>
                                            <Button variant="contained" color="secondary"
                                                    onClick={() => {
                                                        handleRemoveProject(item);
                                                    }}>
                                                <TrashCan/>
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            </RcTweenOne>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
