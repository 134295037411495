import React from 'react';
import './Home.scss';
import Header from './Header/Header';
import Introduction from './Introduction/Introduction';
import Projects from './Projects/Projects';
import Skills from './Skills/Skills';
import Contact from "./Contact/Contact";

export default function Home() {
    return (
        <>
            <Header/>

            <Skills/>

            <Introduction/>

            <Projects/>

            <Contact />
        </>
    );
}
