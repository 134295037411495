import React from 'react';

export default function ContactBackground() {
    return (
        <svg viewBox="0 0 1210 1210" version="1.1" id="svg50">
            <defs>
                <filter id="filter939" colorInterpolationFilters="sRGB">
                    <feFlood floodColor="rgb(0,0,0)" floodOpacity=".49804" result="flood"/>
                    <feComposite in="flood" in2="SourceGraphic" operator="in" result="composite1"/>
                    <feGaussianBlur in="composite1" result="blur" stdDeviation="3"/>
                    <feOffset dx="0" dy="0" result="offset"/>
                    <feComposite in="SourceGraphic" in2="offset" result="composite2"/>
                </filter>
            </defs>
            <g id="g48">
                <circle fill="#333a3f" cx="605" cy="605" r="600" id="circle36" filter="url(#filter939)" />
                <circle fill="#354952" cx="605" cy="605" r="500" id="circle38" />
                <circle fill="#345963" cx="605" cy="605" r="400" id="circle40" filter="url(#filter939)" />
                <circle fill="#2f6972" cx="605" cy="605" r="300" id="circle42" filter="url(#filter939)" />
                <circle fill="#2b7a7e" cx="605" cy="605" r="200" id="circle44" filter="url(#filter939)" />
                <circle fill="#2a8b86" cx="605" cy="605" r="100" id="circle46" filter="url(#filter939)" />
            </g>
        </svg>
    )
}
