import React, {useState} from "react";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';

import * as yup from "yup";
import {ReCAPTCHA} from "react-google-recaptcha";

const formSchema = yup.object().shape({
    contactEmail: yup.string().required('Champ requis').email('Format invalide'),
    contactName: yup.string().required('Champ requis').min(2, 'Minimum 2 charactères'),
    contactObject: yup.string().required('Champ requis').min(4, 'Minimum 4 charactères'),
    contactContent: yup.string().required('Champ requis').min(10, 'Minimum 10 charactères')
})

export default function ContactForm() {
    const [mailReturn, setMailReturn] = useState(undefined)
    const {register, handleSubmit, formState, trigger} = useForm({
        resolver: yupResolver(formSchema)
    })
    const {isValid, errors} = formState
    const onFormSubmit = () => {
        setMailReturn('Mail envoyé (en fait non)')
    }

    console.log(errors)

    return (
        <>
            {mailReturn && <Box mb={4}>{mailReturn}</Box>}
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <TextField
                                onChange={async e => trigger(e.target.name) }
                                fullWidth
                                variant="outlined"
                                name="contactEmail"
                                label="Adresse Mail"
                                inputRef={register}
                                error={!!errors.contactEmail}
                                helperText={errors.contactEmail ? errors.contactEmail.message : ''}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                onChange={async e => trigger(e.target.name) }
                                fullWidth
                                variant="outlined"
                                name="contactName"
                                label="Nom et prénom"
                                inputRef={register}
                                error={!!errors.contactName}
                                helperText={errors.contactName ? errors.contactName.message : ''}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <TextField
                        onChange={async e => trigger(e.target.name) }
                        fullWidth
                        variant="outlined"
                        name="contactObject"
                        label="Objet du message"
                        inputRef={register}
                        error={!!errors.contactObject}
                        helperText={errors.contactObject ? errors.contactObject.message : ''}
                    />
                </Box>

                <Box mb={2}>
                    <TextField
                        onChange={async e => trigger(e.target.name) }
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                        name="contactContent"
                        label="Contenu"
                        inputRef={register}
                        error={!!errors.contactContent}
                        helperText={errors.contactContent ? errors.contactContent.message : ''}
                    />
                </Box>

                <Box mb={2}>
                    <ReCAPTCHA
                        theme="dark"
                        sitekey="6LeVBFAaAAAAAGaza30e9Ssktx9DQrp8houUV0Q9"
                    />
                </Box>

                <Button color="primary" variant={isValid ? 'contained' : 'outlined'} type="submit" fullWidth disabled={!isValid}>Envoyer</Button>
            </form>
        </>
    )
}
