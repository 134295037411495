import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';


class AdminProjectAdd extends React.Component {
    state = {
        title: '',
        link: '',
        shortDescription: '',
        longDescription: '',
        tags: [],
        image: undefined,
    };

    handleSubmit = (event) => {
        event.preventDefault()

        const { title, link, shortDescription, longDescription, tags } = this.state;
        this.props.firebase.ref('projects').orderByChild('order').limitToLast(1).once('value').then(snapshot => {
            let order = 1
            if (snapshot.exists) {
                snapshot.forEach(item => {
                    order = item.val().order + 1
                })
            }
            const ref = this.props.firebase.ref('projects').push({ title, link, shortDescription, longDescription, tags, order })
            this.handleImageUpload(ref)
        })
    }

    handleImageUpload = (projectRef) => {
        const {firebase} = this.props
        let {image} = this.state

        if (image) {
            const fileExt = image.name.split('.').pop();
            const uploadFile = new File([image], `${projectRef.key}.${fileExt}`, {type: image.type})

            firebase.uploadFile(`projects/images`, uploadFile, `${projectRef.path.toString()}/image`)
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleImageChange = (event) => {
        const file = event.target.files[0]

        this.setState({
            image: file,
        })
    }

    handleAddChip = (chip) => {
        this.setState({
            tags: [...this.state.tags, chip]
        })
    }

    handleDeleteChip = (chip) => {
        this.setState({
            tags: this.state.tags.filter(item => item !== chip)
        })
    }

    render() {
        const {title, link, shortDescription, longDescription, tags, image} = this.state

        return (
            <Box mt={2}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5">Ajouter un projet</Typography>

                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={title} fullWidth name="title" label="Titre"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={link} fullWidth name="link"
                                               label="Lien du projet"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={shortDescription} fullWidth multiline rows={3} name="shortDescription"
                                               label="Introduction"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={longDescription} fullWidth multiline rows={3} name="longDescription"
                                               label="Description"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <Box mt={2}>
                                        <ChipInput onAdd={(chip) => this.handleAddChip(chip)}
                                                   onDelete={(chip, index) => this.handleDeleteChip(chip)}
                                                   value={tags}  fullWidth newChipKeyCodes={[188]} name="tags" label="Tags"/>
                                    </Box>
                                </Grid>
                                <Grid item sm={6}>
                                    { image && <img src={URL.createObjectURL(image)} alt={title} style={{maxWidth: '100%'}} /> }
                                    <input type="file" onChange={this.handleImageChange} />
                                </Grid>
                            </Grid>

                            <Box mt={4}>
                                <Button type="submit" color="primary" variant="outlined" disabled={!title || !shortDescription || !longDescription || !tags || tags.length === 0 || !image}>Ajouter</Button>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}

const enhance = connect(
    ({ firebase: { auth, profile } }) => ({
        auth,
        profile
    })
)

export default withFirebase(enhance(AdminProjectAdd))
