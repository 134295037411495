import React from 'react';
import PropTypes from 'prop-types';
import './ScrollDown.scss';
import ScrollAnim from 'rc-scroll-anim';

export default function ScrollDown({ to }) {
    return (
        <ScrollAnim.Link to={to}>
            <div className="scroll-down" />
        </ScrollAnim.Link>
    );
}

ScrollDown.propTypes = {
    to: PropTypes.string,
};

ScrollDown.defaultProps = {
    to: '',
};
