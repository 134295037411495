import React from 'react';
import {
    Box,
    Button, ButtonGroup,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import TweenOne from 'rc-tween-one';


class AdminProjectEdit extends React.Component {
    constructor(props) {
        super(props)

        const {firebase, project} = this.props

        this.state = {
            title: project.title,
            link: project.link,
            shortDescription: project.shortDescription,
            longDescription: project.longDescription,
            tags: project.tags,
            image: undefined,
            defaultImage: undefined
        };

        Object.entries(project.image).forEach(image => {
            firebase.storage().ref(image[1].fullPath).getDownloadURL().then(url => {
                this.setState({defaultImage: url})
            })
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        const ref = this.props.project.snapshot.ref
        const { title, link, shortDescription, longDescription, tags, image } = this.state;

        ref.update({ title, link, shortDescription, longDescription, tags })
        if (image) this.handleImageUpload(ref)
        else this.props.onComplete()
    }

    handleImageUpload = (projectRef) => {
        const {firebase} = this.props
        let {image} = this.state

        if (image) {
            const fileExt = image.name.split('.').pop();
            const uploadFile = new File([image], `${projectRef.key}.${fileExt}`, {type: image.type})

            firebase.uploadFile(`projects/images`, uploadFile, `${projectRef.path.toString()}/image`).then(this.props.onComplete)
        }
        else this.props.onComplete()
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleImageChange = (event) => {
        const file = event.target.files[0]

        this.setState({
            image: file,
        })
    }

    handleAddChip = (chip) => {
        this.setState({
            tags: [...this.state.tags, chip]
        })
    }

    handleDeleteChip = (chip, index) => {
        this.setState({
            tags: this.state.tags.filter(item => item !== chip)
        })
    }

    render() {
        const {title, link, shortDescription, longDescription, tags, image, defaultImage} = this.state

        return (
            <TweenOne animation={{
                type: 'from',
                opacity: 0
            }} mt={2}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5">Modifier: {title}</Typography>

                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={title} fullWidth name="title" label="Titre"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={link} fullWidth name="link"
                                               label="Lien du projet"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={shortDescription} fullWidth multiline rows={3} name="shortDescription"
                                               label="Introduction"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField onChange={this.handleChange} value={longDescription} fullWidth multiline rows={3} name="longDescription"
                                               label="Description"/>
                                </Grid>
                                <Grid item sm={6}>
                                    <Box mt={2}>
                                        <ChipInput onAdd={(chip) => this.handleAddChip(chip)}
                                                   onDelete={(chip, index) => this.handleDeleteChip(chip)}
                                                   value={tags}  fullWidth newChipKeyCodes={[188]} name="tags" label="Tags"/>
                                    </Box>
                                </Grid>
                                <Grid item sm={6}>
                                    { (image || defaultImage) && <img src={image ? URL.createObjectURL(image) : defaultImage} alt={title} style={{maxWidth: '100%'}} /> }
                                    <input type="file" onChange={this.handleImageChange} />
                                </Grid>
                            </Grid>

                            <Box mt={4}>
                                <ButtonGroup>
                                    <Button type="submit" color="primary" variant="outlined" disabled={!title || !shortDescription || !longDescription || !tags || tags.length === 0}>Modifier</Button>
                                    <Button onClick={this.props.onComplete} variant="outlined">Annuler</Button>
                                </ButtonGroup>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </TweenOne>
        );
    }
}

const enhance = connect(
    ({ firebase: { auth, profile } }) => ({
        auth,
        profile
    })
)

export default withFirebase(enhance(AdminProjectEdit))
