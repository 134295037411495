import React from 'react';
import './Navalogo.scss';
import RcTweenOne from 'rc-tween-one';
import SvgDrawPlugin from 'rc-tween-one/lib/plugin/SvgDrawPlugin';

export default function Navalogo() {
    const svgLogoAnimation = {
        SVGDraw: 0,
        duration: 1000,
        delay: 1000,
        type: 'from',
    };
    const svgLogoEllipseAnimation = {
        type: 'from',
        opacity: 0,
        duration: 1000,
        delay: 1000,
    };
    RcTweenOne.plugins.push(SvgDrawPlugin);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108.8 105.6" className="navalogo">
            <defs />
            <g stroke=" #EEE">
                <RcTweenOne
                    animation={svgLogoAnimation}
                    component="path"
                    fill="none"
                    d="M46.125 45.382c2.025-6.26 6.37-6.885 9.757-5.833 2.536471.897118 3.342 2.81 4.467 5.791.94 3.422.893 7.023-1.724 9.988-2.94 2.85-4.929 2.711-8.033 1.763-2.407-.857-4.007-3.656-4.584-5.456-.459-1.479-.596-3.873.117-6.253zm-20.986 5.941c.995901-7.174002 4.361777-6.463113 4.765588-11.489177.360625-6.297059-9.018262-4.862029-12.179026-1.045499C15.47497 41.837839 13.689207 45.731619 16.638 49.687c3.93 4.742 6.476 2.027 8.501 1.637z"
                />
                <RcTweenOne
                    animation={svgLogoEllipseAnimation}
                    component="ellipse"
                    cx="21.775"
                    cy="46.482"
                    rx="3.091"
                    ry="3.114"
                />
                <RcTweenOne
                    animation={svgLogoEllipseAnimation}
                    component="ellipse"
                    cx="53.707"
                    cy="52.013"
                    rx="3.821"
                    ry="3.903"
                />
                <g fill="none">
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M40.095 39.14l-.591-5.228 4.228-3.41.818 6.001z"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M39.504 33.913l5.046 2.591"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M40.095 39.14l3.637-8.637"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M86.084 50.984c-.098 2.452-.403 5.052-.935 7.798-3.396 14.7-9.025 15.315-10.226 16.592m6.055-43.898a25.269 25.269 0 013.018 5.583"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M83.775 63.825c7.94-7.202 17.937-3.473 18.52 2.916-6.003-2.07-10.198.895-12.776 8.917-1.94 6.277-2.607 9.319-4.43 11.061-1.646 1.398-2.243 1.933-6.847 2.463"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M58.117 89.04c-3.376-23.383 11.46-25.403 25.394-28.48-5.05 5.012-10.855 9.639-7.779 17.872 7.608 15.741-3.575 21.996-9.516 22.015-8.894.2-14.143-4.173-14.496-8.096"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M50.242 86.147c4.23-.979 6.485-1.957 7.2-2.507m-3.375 4.95c-5.981 9.376-13.017 21.539-25.651 14.754-13.478-8.957 2.908-33.317 10.801-34.009-.589 7.95-2.554 16.781 3.954 21.183"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M25.139 51.323c5.017 7.25 15.773 10.428 20.635 5.765 2.36 11.571 1.495 22.064-7.136 20.38m-5.943-4.224c-.156-6.474-1.533-12.256-2.852-17.157m-2.279 15.885c.79.957 1.462 2.454 2.925 3.729"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M23.626 95.276c-5.243 1.73-25.455 1.038-17.615-17.551 4.292-7.377 10.9-12.961 3.697-17.262-4.053-1.554-6.7.105-9.612 1.157 3.576-7.678 8.749-10.069 13.886-8.679 14.552 4.6 17.91 15.521 8.712 31.437"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M20.957 56.324l-.09-4M9.732 72.015c-1.527-1.098-1.106-3.42-.55-5.826.364-1.807.89-2.575 1.092-5.421m2-8.263c.561-5.215 1.884-10.358-5.046-16.274 2.856-.854 7.006019.298086 10.166019 2.414086M46.755 69.916c-2.797-.496-5.272-.496-7.524.705M23.427045 35.535853c2.069339-4.041106 5.885861-6.081257 9.584955-6.862853 9.676-9.2 19.435-8.58 30.45-7.144 3.368.41 6.544 1.375 9.393 2.883"
                    />
                    <RcTweenOne
                        animation={svgLogoEllipseAnimation}
                        component="ellipse"
                        cx="80.547"
                        cy="52.219"
                        rx="3.865"
                        ry="4.337"
                        transform="matrix(.99693 -.0783 .00987 .99995 0 0)"
                    />
                </g>
                <RcTweenOne
                    animation={svgLogoEllipseAnimation}
                    component="ellipse"
                    cx="83.046"
                    cy="46.255"
                    rx="2.875"
                    ry="3.886"
                    transform="matrix(.99999 -.00365 -.0292 .99957 0 0)"
                />
                <g fill="none">
                    <RcTweenOne
                        animation={svgLogoEllipseAnimation}
                        component="ellipse"
                        cx="90.299"
                        cy="9.769"
                        rx="8.574"
                        ry="10.376"
                        transform="matrix(.91275 .40851 -.35813 .93367 0 0)"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M73.074 32.345c-4.106.725-5.972 2.18-8.388 4.817-4.451 5.287-3.73 10.116-2.264 14.546 1.123 3.361 4.208 6.841 8.844 7.478 4.833.665 12.414-3.125 13.948-6.776"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M39.752 23.739c1.183-3.846 3.305-7.216 5.853-9.48 6.225-5.356 14.01-5.822 18.865-3.21 4.652 2.11 7.498 5.957 8.28 11.79.777 4.527.414 9.325-.113 14.018l2.018.412c1.072-10.848.61-10.957.035-14.633-1.147-4.794-2.484-9.529-8.82-12.733-8.789-4.776-17.204-.598-22.071 3.305-4.2 3.76-5.653 8.294-6.274 11.865"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M80.401 35.789c1.522-8.074.623-15.281-3.69-21.264-5.052-5.663-10.263-6.512-17.458-6.67m10.093814 50.908224C66.039814 56.466224 63.044 52.194 63.4 46.518c.203-4.449 1.83-7.572 4.216-10.071 1.07-1.09 3.281-2.383 5.402-2.893"
                    />
                    <RcTweenOne
                        animation={svgLogoAnimation}
                        component="path"
                        d="M80.776 33.592c1.144.588 3.287 2.44 4.422 4.627M45.462 21.616c1.774-7.117 5.394-9.752 9.655-11.892m17.679 25.807c-3.675 1.481-6.35 5.08-6.95 9.838-.46 5.125 1.378 8.625 5.14 10.687 4.847 2.253 7.492.552 9.166-.018"
                    />
                </g>
            </g>
        </svg>
    );
}
