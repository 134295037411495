import React from 'react';
import './App.scss';
import Home from './Home/Home';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Admin from './Admin/Admin';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/admin">
                    <Admin />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
