import React from 'react';
import './Skills.scss';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import RcTweenOne from 'rc-tween-one';
import { OverPack } from 'rc-scroll-anim';
import DrawVR from './icons/virtualreality.svg';
import DrawWeb from './icons/website.svg';
import DrawShop from './icons/shop.svg';
import TweenOne from "rc-tween-one";

function SkillColumn({
    title,
    description,
    draw,
    colID,
    animPaper
}) {
    const animation = {
        type: 'from',
        rotateX: '90deg',
        opacity: 0,
        scale: 1.4,
        playScale: 0.5,
        ...animPaper
    };

    return (
        <OverPack component={Grid} playScale={0.3} className="skill-column" item md={4}>
            <RcTweenOne component={Card} key={colID + '_card'} raised animation={{
                type: 'from',
                opacity: 0,
            }} style={{height: '100%'}}>
                <OverPack component={CardContent} playScale={0.3}>
                    <RcTweenOne
                        key={colID + '_paper'}
                        component="div"
                        className="skill-title"
                        animation={animation}>
                        <Typography variant="h4">{title}</Typography>
                    </RcTweenOne>
                    <RcTweenOne
                        key={colID + '_draw'}
                        component="img"
                        alt={title}
                        src={draw}
                        animation={{
                            type: 'from',
                            opacity: 0,
                            scale: 0
                        }}
                    />
                    <RcTweenOne
                        key={colID + '_text'}
                        component={Typography}
                        variant="subtitle1"
                        animation={{
                            type: 'from',
                            opacity: 0,
                        }}
                    >{description}</RcTweenOne>
                </OverPack>
            </RcTweenOne>
        </OverPack>
    );
};

export default function Skills() {
    return (
        <div className="nava-section skills" id="section1">
            <OverPack playScale={0.5} component="div" className="nava-title">
                <TweenOne component={Card} key="testitest2" raised style={{marginBottom: '80px'}} animation={{type: 'from', y: -100, opacity: 0}}>
                    <CardContent className="card-titled">
                        <div className="skill-title">
                            <Typography variant="h2">Services</Typography>
                        </div>
                    </CardContent>
                </TweenOne>
            </OverPack>
            <Grid className="skill-row" container spacing={0}>
                <SkillColumn
                    title="Visite Virtuelle"
                    description="Rendez vos boutiques/lieux de visites
                        accessibles en ligne grâce à la réalité virtuelle. Je vous propose la mise
                        en place de visite virtuelle avec une intégration sur-mesure afin de donner
                        un tout autre aspect à votre site web. Avec ou sans casque VR, l'experience
                        est adapté pour tout type de support."
                    draw={DrawVR}
                    colID="skill_vr"
                    animPaper={{}}
                />
                <SkillColumn
                    title="Site Sur-mesure"
                    description="Demarquez-vous de la concurrence avec un site
                        web qui correspond vraiment à votre image de marque. Je vous accompagne tout
                        le long de la création de votre site, et rend vos idées
                        réalité."
                    draw={DrawWeb}
                    colID="skill_web"
                    animPaper={{}}
                />
                <SkillColumn
                    title="E-Boutique"
                    description="Digitalisez votre business grâce au E-Commerce.
                        A l'aide de différents outils, je vous propose la mise en place rapide et
                        efficace d'une boutique en ligne sécurisé et facile
                        d'utilisation."
                    draw={DrawShop}
                    colID="skill_shop"
                    animPaper={{}}
                />
            </Grid>
        </div>
    );
};
