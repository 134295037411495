import React, {useEffect, useState} from 'react';
import './Contact.scss'
import {
    Card,
    CardContent, Divider,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@material-ui/core";
import ContactBackground from "./Background";
import {useFirebase} from "react-redux-firebase";
import RcTweenOne from "rc-tween-one";
import RcScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import TweenOne from "rc-tween-one";
import {OverPack} from "rc-scroll-anim";
import ContactForm from "./Form";

export default function Contact() {
    const firebase = useFirebase()
    const [contactInfos, setContactInfos] = useState([])

    useEffect(() => {
        firebase.ref('configs/contactInfos').on('value', snapshot => {
            if (snapshot.exists()) setContactInfos(snapshot.val())
        })
    }, [firebase, setContactInfos])
    return (
        <div className="nava-section nava-contact">
            <ContactBackground/>

            <div className="nava-contact-body">
                <OverPack playScale={0.4} component="div" className="nava-title">
                    <TweenOne component={Card} key="testitest" raised style={{marginBottom: '80px'}} animation={{type: 'from', y: -100, opacity: 0}}>
                        <CardContent className="card-titled">
                            <div className="skill-title">
                                <Typography variant="h2">Contact</Typography>
                            </div>
                        </CardContent>
                    </TweenOne>
                </OverPack>

                <Grid container spacing={4} justify="space-around">
                    <OverPack component={Grid} playScale={0.2} item md={4} style={{width: '100%'}}>
                        <TweenOne component={Card} key="testitest" animation={{type: 'from', x: -50, y: -100, opacity: 0}}>
                            <CardContent>
                                <Typography variant="h6" align="center">M'envoyer un message</Typography>
                            </CardContent>
                            <Divider/>

                            <CardContent>
                                <Typography variant="body1" align="center">N'hésitez pas à utiliser ce formulaire pour me contacter </Typography>
                            </CardContent>
                            <Divider/>

                            <CardContent>
                                <ContactForm />
                            </CardContent>
                        </TweenOne>
                    </OverPack>
                    <OverPack component={Grid} playScale={0.2} item md={4} style={{width: '100%'}}>
                        <TweenOne component={Card} key="testitest2" animation={{type: 'from', x: 50, y: -100, opacity: 0}}>
                            <CardContent>
                                <Typography variant="h6" align="center">Informations</Typography>
                            </CardContent>
                            <Divider/>
                            <Table>
                                <RcScrollOverPack component={TableBody} playScale={0.2}>
                                    {!contactInfos.length ? <TableRow><TableCell
                                        colSpan={2}><LinearProgress/></TableCell></TableRow> : contactInfos.map((item, key) => (
                                        <RcTweenOne
                                            component={TableRow}
                                            key={item.uid}
                                            animation={{
                                                type: 'from',
                                                x: -100,
                                                opacity: 0,
                                                delay: 100 * key
                                            }} hover>
                                                <TableCell align="right">{item.name}</TableCell>
                                                <TableCell><span
                                                    dangerouslySetInnerHTML={{__html: item.value}}/></TableCell>
                                        </RcTweenOne>
                                    ))}
                                </RcScrollOverPack>
                            </Table>
                        </TweenOne>
                    </OverPack>
                </Grid>
            </div>
        </div>
    )
}
